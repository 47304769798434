import * as React from 'react';
import { getClues, manageClue } from '../../services/AppService';

export interface AddClueProps {
}

export function AddClue(_props: AddClueProps) {
    const [clueLetters, setClueLetters] = React.useState('');
    const [clueDate, setClueDate] = React.useState('');
    const [solutionWord, setSolutionWord] = React.useState('');
    const [solutionExplanation, setSolutionExplanation] = React.useState('');
    const [hint, setHint] = React.useState('');
    const [isValid, setIsValid] = React.useState(false);

    const addClue = () => {
        manageClue({
            Id: 0,
            ClueLetters: clueLetters,
            ClueDate: clueDate,
            SolutionWord: solutionWord,
            SolutionExplanation: solutionExplanation,
            Hint: hint
        }).then((_clue) => {
            window.alert('Clue added successfully!');
            window.location.reload();
        });
    }

    React.useEffect(() => {
        getClues().then((clues) => {
            console.log('clues', clues);
        })
    }, []);

    React.useEffect(() => {
        setIsValid(clueLetters !== '' && clueDate !== '' && solutionWord !== '' && solutionExplanation !== '' && hint !== '');
    }, [clueLetters, clueDate, solutionWord, solutionExplanation, hint]);

    return (
        <div className='add-clue-form'>
            <h4>Add a Clue</h4>
            <div className='fields'>
                <div className='field'><span>Date:</span><input type="date" id="clueDate" name="clueDate" placeholder='Select date' value={clueDate} onChange={(e) => { setClueDate(e.target.value.toString()); }} /></div>
                <div className='field'><span>Clue Letters:</span><input type="text" id="clue" name="clue" value={clueLetters} onChange={(e) => setClueLetters(e.target.value.toUpperCase())} /></div>
                <div className='field'><span>Final Word: </span><input type="text" id="solutionWord" name="solutionWord" value={solutionWord} onChange={(e) => setSolutionWord(e.target.value.toUpperCase())} /></div>
                <div className='field'><span>Explanation:</span><input type="text" id="solutionExplanation" name="solutionExplanation" value={solutionExplanation} onChange={(e) => setSolutionExplanation(e.target.value)} /></div>
                <div className='field'><span>Hint:</span><input type="text" id="hint" name="hint" value={hint} onChange={(e) => setHint(e.target.value)} /></div>
            </div>
            <div className='buttonBar' style={{ marginTop: '20px' }}>
                <button className={!isValid ? ' disabled' : ''} disabled={!isValid} onClick={addClue}>Submit</button>
            </div>
        </div>
    );    
}