import * as React from 'react';
import Confetti from 'react-confetti'

export interface JordanSecretPageProps {
}

export function JordanSecretPage(_props: JordanSecretPageProps) {
    return (
        <div style={{ marginBottom: 'auto' }}>
            <div style={{ position: 'relative' }}>
                <h2 style={{ marginBottom: '40px' }}>CONGRATS JORDAN!!!</h2>
                <div className='fields'>You've found the hidden page!</div>
                <div style={{ margin: '40px 0' }}>I love you.</div>
                <div>-Grayson</div>
            </div>
            <Confetti
                recycle={false}
                numberOfPieces={2000}
                tweenDuration={20000}
            />
        </div>
    );    
}