import { GameData, PlayerData, Clue } from "../components/Game";

export async function getPlayer(playerGuid: string): Promise<PlayerData> {
  return fetch("/api/getPlayer?guid=" + playerGuid)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("Error getting players.\n", err);
    });
}

export async function managePlayer(playerData: PlayerData): Promise<PlayerData> {
  return fetch("/api/managePlayer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(playerData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((err) => {
    console.log("Error saving player.\n", err);
  });
}

export async function getClueForToday(date: string): Promise<Clue> {
  return fetch("/api/getClueForToday?date=" + date)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("Error getting today's clue.\n", err);
    });
}

export async function getClues(): Promise<PlayerData> {
  return fetch("/api/getClues")
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("Error getting clues.\n", err);
    });
}

export async function manageClue(clueData: Clue): Promise<Clue> {
  return fetch("/api/manageClue", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(clueData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((err) => {
    console.log("Error saving clue.\n", err);
  });
}

export async function getGame(playerId: number, gameDate: string): Promise<GameData> {
  return fetch(`/api/getGame?playerId=${playerId}&date=${gameDate}`)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("Error getting game.\n", err);
    });
}

export async function manageGame(GameData: GameData): Promise<GameData> {
  return fetch("/api/manageGame", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(GameData),
  })
  .then((res) => {
    return res.json();
  })
  .catch((err) => {
    console.log("Error saving game.\n", err);
  });
}

export async function getHomeData(playerGuid: string, date: string): Promise<string> {
  return fetch(`/api/getHomeData?playerGuid=${playerGuid}&date=${date}`)
    .then((res) => {
      return res.json();
    })
    .catch((err) => {
      console.log("Error getting home data.\n", err);
    });
}